<template>
  <div class="home">

    <h1 class="title">Beneficiaries</h1>
    <div class="content">
      <!--      <h2>This are Beneficiaries you can select on our Plattform</h2><br>-->
      <div class="container" v-if="beneficiaries.length>0">
        <div class="card" v-for="item of beneficiaries" :key="item.id">
          <a :href="item.website" target="_blank">
            <h2>{{ item.name }}</h2>
          </a>
          <p>{{ item.description | truncate(100) }}</p>
          <br>
          <div class="flexCenterCenter">
            <chain-symbol-address :chain-id="item.chainId" :tx="item.publicAddress"/>
            <!--            <input type="text" :value="item.publicAddress" disabled>-->
          </div>
          <!--          <p>Member of this platform since: {{ item.created_at | isoLocalDateTime }}</p>-->

        </div>
      </div>
      <div v-else class="flexCenterCenter">
        <img class="illustration" src="@/assets/no_data.svg" alt="">
        <h2>No Beneficiaries to show</h2>
      </div>
    </div>


  </div>
</template>

<script>
import axios from 'axios'
import Api from "../services/Api";
import ChainSymbolAddress from "../components/networkSettings/chainSymbolAddress";

export default {
  name: 'Beneficiaries',
  components: {ChainSymbolAddress},
  data() {
    return {
      beneficiaries: []
    }
  },
  mounted() {
    this.init()
  },
  activated() {
    this.init()
  },
  methods: {
    async init() {
      const response = await axios.get(Api.baseUrl + '/receivers')
      this.beneficiaries = response.data
    },

  },
}
</script>
 <style scoped lang="scss">
@import 'src/styles/style.scss';

img {
  max-width: 100%;
  border-radius: 12px;
  margin-bottom: 16px;
}


.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 18px 18px;
  grid-auto-flow: row;

}



.nft_listing {
  //max-width: 100%;

  img {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 16px;
  }
}

.nft_upper_meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
