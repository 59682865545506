<template>
  <div class="demo">
    <web3Demo></web3Demo>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import web3Demo from "../components/dev/web3Demo";

export default {
  name: 'DemoWeb3JS',
  components: {
    web3Demo
  }
}
</script>
