<template>
  <div>
    <h1 class="title">Web3 JS Demo</h1>
    <div class="content">
      <h2>Block explorer</h2>
      <button @click="getContract">1) get contract storage</button>
      <br>
      <input type="number" v-model="numberToStore">
      <button @click="storeNumber(numberToStore)">2) storeNumber</button>
      <br>
      <button @click="retrieveNumber">3) retrieveNumber</button>
      <br>


      <br>
      <br>
      <br>

      <input type="number" v-model="selectedBlockNumber">
      <button @click="getBlock(selectedBlockNumber)">Get Block</button>
      <div class="card" v-if="selectedBlock">
        <p>Block number: {{ selectedBlock.number }}</p>
        <p>Block Time stamp: {{ moment(selectedBlock.timestamp) }}</p>
        <p>Block Gas Limit: {{ selectedBlock.gasLimit }}</p>
        <p>Block Gas Used: {{ selectedBlock.gasUsed }}</p>
        <p>Block hash: {{ selectedBlock.hash }}</p>
        <p>Block parentHash: {{ selectedBlock.parentHash }}</p>
        <!--        <p>Block mixHash: {{ selectedBlock.mixHash }}</p>-->
        <div>block transactions:
          <div v-for="transaction in selectedBlock.transactions" :key="transaction">
            <b title="click to fetch transaction data" @click="getTransaction(transaction)">{{ transaction }}</b>
          </div>
        </div>
        <!--    {{ selectedBlock }}-->

        <div class="card" v-if="selectedTransaction">
          <h2>Transaction Data:</h2>
          <p>From: {{ selectedTransaction.from }}</p>
          <p>Value: {{ selectedTransaction.value }}</p>
          <p>Gas: {{ selectedTransaction.gas }}</p>
          <p>Gas Price: {{ selectedTransaction.gasPrice }}</p>
          <!--          <pre>{{ selectedTransaction }}</pre>-->
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

const Web3 = require('web3');
export default {
  name: "web3Demo",
  data() {
    return {
      moment: moment,
      Web3: null,
      selectedBlockNumber: 1,
      selectedBlock: null,
      selectedTransaction: null,
      contract: null,
      numberToStore: 0,
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      this.Web3 = new Web3('HTTP://127.0.0.1:7545');
      console.log(this.Web3);
      console.log(this.Web3.eth)
    },
    async getBlock(blockHashOrBlockNumber) {
      this.selectedBlock = await this.Web3.eth.getBlock(blockHashOrBlockNumber)
    },
    async getTransaction(transaction) {
      this.selectedTransaction = await this.Web3.eth.getTransaction(transaction)
    },

    async getContract() {
      // der contrakt wurde mit Remix
      // (verbunden mit der localen Block networkSettings "deploy & run transactions)
      // ENVIRONMENT muss Web3 Provider sein und mit Trouffle verbunden sein z.b RPC SERVER HTTP://127.0.0.1:7545
      // unter SOLIDITY COMPILER kann man die API holen, (json interface)
      // Ardess erhält man nach dem deployen und steht im block als transactions
      this.contract = new this.Web3.eth.Contract([
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "num",
              "type": "uint256"
            }
          ],
          "name": "store",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "retrieve",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        }
      ], '0x10414e12bB04E276a04fe32554569d34365809b5')
      console.log(this.contract)
    },

    async storeNumber(numberToStore) {
      // https://web3js.readthedocs.io/en/v1.2.11/web3-eth-contract.html#id27
      let resp = await this.contract.methods.store(numberToStore).send(
          {from: '0x389cD8C383213824E3bc400086e3F6B267e85122'}
      )
      await this.getBlock(resp.blockNumber)
      console.log(resp)
    },

    async retrieveNumber() {
      let resp = await this.contract.methods.retrieve().call()
      console.log(resp)
    }

  },
}
</script>

 <style scoped lang="scss">
@import 'src/styles/style.scss';


</style>