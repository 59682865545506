<template>
  <div class="container">
    <a :href="item.link" target="_blank" rel="nofollow" class="card" :key="item.title"
       v-for="item of decrypt.items">
      <img :src="item.enclosure.url" alt="">
      <h2>{{ item.title }}</h2><br>
      <p>{{ item.contentSnippet }}</p>
    </a>
  </div>
</template>

<script>
// My own Ethers API Wrapper Service named ChainAPI
let Parser = require('rss-parser');
let parser = new Parser();


export default {
  name: 'News',
  components: {},
  data() {
    return {
      decrypt: [],

    }
  },
  mounted() {
    this.init()
  },
  activated() {
    this.init()
  },
  methods: {
    async init() {
      await this.getDecryptFeed()
    },

    async getDecryptFeed() {
      this.decrypt = await parser.parseURL('https://decrypt.co/feed');
      // console.log(this.decrypt.title);
      this.decrypt.items.length = 4;

      // this.decrypt.items.forEach(item => {
        // console.log(item.title + ':' + item.link)
      // });
    },


  },
}
</script>
 <style scoped lang="scss">
@import 'src/styles/style.scss';

img {
  max-width: 100%;
  border-radius: 12px;
  margin-bottom: 16px;
}

.content {
  margin-bottom: 100px;
}


.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 18px 18px;
  grid-auto-flow: row;

}



.nft_listing {
  //max-width: 100%;

  img {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 16px;
  }
}

.nft_upper_meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
