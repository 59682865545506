<template>
  <div class="home">
    <h1 class="title">Collections </h1>
    <div class="content">

<!--      <h2>Explore and Mint NFT from Artists. Be the first, who buys fresh NFT direct from the Artists.</h2>-->
<!--      <p>Here you find Limited Generated or Hand made and digitized Art Unikats. Enjoy minting!</p>-->
<!--      &lt;!&ndash;      <h3>Overview Page</h3>&ndash;&gt;-->

<!--      &lt;!&ndash;      <pre>&ndash;&gt;-->
<!--      &lt;!&ndash;        {{ listings }}&ndash;&gt;-->
<!--      &lt;!&ndash;      </pre>&ndash;&gt;-->
<!--      <br>-->
      <div class="container" v-if="collections.length>0">
        <div :key="collection.id" v-for="collection in collections" class="nft_listing">
          <collectionOverview :data="collection"/>
        </div>
      </div>
      <div v-else class="flexCenterCenter">
        <img class="illustration" src="@/assets/no_data.svg" alt="">
        <h2>No Collections to show</h2>
      </div>

    </div>

    <!--    <img alt="Vue logo" src="../assets/logo.png">-->
    <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
</template>

<script>
import axios from "axios";
import Api from "../services/Api";
import collectionOverview from "../components/nft/collectionOverview";
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
export default {
  name: 'Collections',
  components: {
    collectionOverview,
    // HelloWorld
  },
  data() {
    return {
      collections: []
    }
  },
  mounted() {
    this.init()
  },
  activated() {
    this.init()
  },
  methods: {
    async init() {
      await this.getListings();
    },
    async getListings() {

      axios.get(Api.baseUrl + "/collections").then(async (response) => {
        // console.log(response.data);
        this.collections = response.data
      }).catch(function (error) {
        console.error(error);
      });

    }
  },
}
</script>
 <style scoped lang="scss">
@import 'src/styles/style.scss';

.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 18px 18px;
  grid-auto-flow: row;
}



.nft_listing {
  //max-width: 100%;
  background: var(--white);

  img {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 16px;
  }
}

.nft_upper_meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
