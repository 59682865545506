<template>
  <div class="home">
    <h1 class="title">My Nfts</h1>
    <div class="content">

<!--      <h2>Overview Page</h2>-->

      <div class="container" v-if="nfts.length>0">
        <div :key="nft.id" v-for="nft in nfts" class="nft_listing">
          <nft-overview :inputNft="nft" :key="nft.id"></nft-overview>
        </div>
      </div>
      <div v-else class="flexCenterCenter">
        <img class="illustration" src="@/assets/no_data.svg" alt="">
        <h2>No NFT to show</h2>
      </div>

    </div>

    <!--    <img alt="Vue logo" src="../assets/logo.png">-->
    <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
</template>

<script>
import axios from "axios";

import NftOverview from "../components/nft/nftOverview";
import Api from "../services/Api";
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
export default {
  name: 'MyNfts',
  components: {
    NftOverview
    // HelloWorld
  },
  data() {
    return {
      nfts: []
    }
  },
  mounted() {
    this.init()
  },
  activated() {
    this.init()
  },
  methods: {
    async init() {
      await this.getListings();
    },
    async getListings() {


      axios.get(Api.baseUrl + "/myNfts").then(async (response) => {
        console.log(response.data);
        this.nfts = response.data
      }).catch(function (error) {
        console.error(error);
      });


    }
  },
}
</script>
 <style scoped lang="scss">
@import 'src/styles/style.scss';

.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 18px 18px;
  grid-auto-flow: row;
}



.nft_listing {
  //max-width: 100%;

  img {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 16px;
  }
}

.nft_upper_meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
