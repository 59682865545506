<template>
  <div class="home">

    <h1 class="title">1) Collection for your NFT</h1>
    <div class="content" v-if="!collection.contract">
      <h2>How do your want to proceed?</h2>


      <div class="switch-field">
        <input type="radio" id="radio-one" name="switch-one" :value="true" checked/>
        <label
            @click="toggle.newContract = true; toggle.selectContract = false; toggle.importContract = false; collection.address = ''"
            for="radio-one">New Collection</label>
        <input type="radio" id="radio-two" name="switch-one" value="no"/>
        <label @click="toggle.selectContract = true; toggle.importContract = false; toggle.newContract = false"
               for="radio-two">Mint into a existing Collection</label>
<!--        <input type="radio" id="radio-tree" name="switch-one" value="no"/>-->
<!--        <label @click="toggle.importContract = true; toggle.selectContract = false; toggle.newContract = false"-->
<!--               for="radio-tree">Import Contract</label>-->
      </div>
      <br>


      <div class="" v-if="toggle.newContract">
        <br>
        <h2>Create a new Collection</h2>
        <!--        <div class="col2">-->

        <div class="card">

          <h2>Create your NFT</h2>

          <div class="flex" style="display:flex; align-items: center; flex-wrap: nowrap;">
            <!--      <label for="collection.name">Name of your NFT Collection</label>-->
            <input :name="collection.name" :id="collection.name" v-model="collection.name" type="text"
                   placeholder="Collection Name">
            <!--      <label for="collection.symbol">3 Character Symbol for your Collection</label>-->
            <input :name="collection.symbol" :id="collection.symbol" maxlength="5" style="width: 100px;"
                   v-model="collection.symbol" type="text"
                   placeholder="Symbol">
          </div>
          <textarea v-model="collection.description" rows="10" placeholder="Whats your Collection about?  + What do the NFTs in this collection stand for? What is the real value? (e.g. Copyright, Commercial Rights....) + Where can I verify the authenticity of this collection (original website, real world institution)?">
        </textarea>

          <input type="url" name="website" v-model="collection.website" id=""
                 placeholder="Collection / Project Website">


<!--          <label for="moreSettings" class="checkBoxLabel">-->
<!--            <input class="checkBox" id="moreSettings" type="checkbox" v-model="toggle.moreSettings">-->
<!--            <span>Adjust more Settings [DEV]</span>-->
<!--          </label>-->

<!--          <div v-if="toggle.moreSettings">-->


<!--            <label for="isLimited" class="checkBoxLabel">-->
<!--              <input class="checkBox" id="isLimited" type="checkbox" v-model="toggle.isLimited">-->
<!--              <span>Limit Your NFT Collection</span>-->
<!--            </label>-->
<!--            <input type="number" v-if="toggle.isLimited" v-model="collection.mintingLimit"-->
<!--                   placeholder="Max. Mintable NFTs in this Collection">-->


<!--            <label for="isGenerativ" class="checkBoxLabel">-->
<!--              <input class="checkBox" id="isGenerativ" type="checkbox" v-model="toggle.isGenerativ">-->
<!--              <span>Generated Artwork (Requires own Hosting)</span>-->
<!--            </label>-->
<!--            <input type="text" v-model="collection.baseUrl" v-if="toggle.isGenerativ"-->
<!--                   placeholder="Base URI of your NFT">-->


<!--            <label for="isSelfMinting" class="checkBoxLabel" v-if="toggle.isGenerativ">-->
<!--              <input class="checkBox" id="isSelfMinting" type="checkbox" v-model="toggle.isSelfMinting">-->
<!--              <span>Allow Users to mint Your NFTs by them self</span>-->
<!--            </label>-->

<!--            <div v-if="toggle.isSelfMinting">-->
<!--              <input type="number" v-model="collection.priceSelfMint"-->
<!--                     placeholder="Price to charge for Minting your NFT">-->
<!--              <p class="inputHelp">Price in ETH and additional to network cost. We charge 2.5%-->
<!--                of your Fee on Minting</p>-->
<!--              <br>-->
<!--            </div>-->

<!--            <label for="hasRevenueShare" class="checkBoxLabel" v-if="toggle.isSelfMinting">-->
<!--              <input class="checkBox" id="hasRevenueShare" type="checkbox" v-model="toggle.hasRevenueShare">-->
<!--              <span>Activate revenue Share (Or Donations)</span>-->
<!--            </label>-->
<!--            <div v-if="toggle.hasRevenueShare">-->
<!--              <div class="col2">-->
<!--                <select name="" id="" v-model="revenueShareAddress">-->
<!--                  <option value="" selected>Custom Address</option>-->
<!--                  <option v-for="org in receivers" :key="org.id" :value="org.publicAddress">{{ org.name }}</option>-->
<!--                </select>-->
<!--                <div class="flex noWrap" v-if="toggle.hasRevenueShare">-->
<!--                  <input type="text" v-if="!toggle.editReceiverAddress" placeholder="Recipient address"-->
<!--                         :value="revenueShareAddress" disabled>-->
<!--                  <input type="text" v-if="revenueShareAddress.length < 0 || toggle.editReceiverAddress"-->
<!--                         v-model="revenueShareAddress" placeholder="Recipient address">-->
<!--                  <button class="chip noWordBreak" @click="toggle.editReceiverAddress = !toggle.editReceiverAddress">-->
<!--                    Edit-->
<!--                  </button>-->
<!--                </div>-->
<!--              </div>-->


<!--              <div class="col5">-->
<!--                <input max="100" style="min-width: 80px" v-model="revenueSharePercent" type="number"-->
<!--                       v-if="toggle.hasRevenueShare"-->
<!--                       placeholder="%">-->
<!--                <button @click="revenueSharePercent = 5">5%</button>-->
<!--                <button @click="revenueSharePercent = 10">10%</button>-->
<!--                <button @click="revenueSharePercent = 25">25%</button>-->
<!--                <button @click="revenueSharePercent = 50">50%</button>-->
<!--              </div>-->

<!--              &lt;!&ndash;            <input type="number" v-if="toggle.hasRevenueShare" placeholder="Percent rate of Revenue">&ndash;&gt;-->
<!--              <p class="inputHelp">-->
<!--                Paste your own or select on of our donation-->
<!--                partners to plant Trees or help healing kids-->
<!--              </p>-->
<!--              <br>-->
<!--            </div>-->

<!--          </div>-->

          <!--      <label for="collection.baseUrl">Base URL for your Listing</label>-->
          <!--      <input name="collection.baseUrl" v-model="collection.baseUrl" type="text" placeholder="Collection baseURL"><br><br>-->

          <div class="ctaWithChainCheck" v-if="!loading">
            <chain-switch-small></chain-switch-small>
            <button v-if="collection.symbol && collection.name" class="cta" @click="deployContract">
              Create Collection
            </button>
            <button v-else class="ctaSized">Create Collection</button>
          </div>
          <loader v-if="loading"/>
        </div>


      </div>
      <div class="card" v-if="toggle.selectContract">
        <h2>Select an existing Collection Contract</h2>

        <!--        <h2>!! achtung Select nur die der actuellen Chain (in backend filtern)</h2>-->
        <!--        <p>Use a Collection you created on this website</p>-->
        <p>Select one of your Collections you created on this Plattform and go to next step</p>
        <br>
        <div style="display: flex; overflow: auto; gap: 16px; padding-bottom: 16px">
          <div v-for="collectionItem of MyCollections" :key="collectionItem.id"
               @click="selectCollection(collectionItem)"
          >

            <collection-overview-small
                v-bind:class="{selectedBorder: collection.address === collectionItem.collectionAddress}"
                style="max-width: 300px; min-width:300px;"
                :data="collectionItem"/>

          </div>

        </div>
        <br>
        <!--        {{ collection.address }}-->

        <!--        <select name="" id="" v-model="collection.address" @change="importExisting">-->
        <!--          <option v-for="collection of MyCollections" :key="collection.id" :value="collection.collectionAddress">-->
        <!--            {{ collection.collectionName }} | Chain: {{ collection.chainId }} | {{ collection.collectionAddress }}-->
        <!--          </option>-->
        <!--        </select>-->
      </div>


      <div class="card" v-if="toggle.importContract">

        <h2>Import a ERC721 Collection Contract</h2>
        <p>Be sure, you are connectet to the right Blockchain</p>
        <br>
        <div class="ctaWithChainCheck">
          <chain-switch-small :address="collection.address"/>
          <input type="text" v-model="collection.address" placeholder="Smart Contract address of your NFT Collection">
        </div>
        <br>
        <collapsible>
          <template v-slot:cta>
            How can i verify my Import?
          </template>
          <template>
            <p>Pleas select the right Chain, before your proceed with minting your ERC 721 compatible NFT. YOu can check
              the date on the Search icon.</p>
            <p>Import Collection using Contract address (Mint into Third Party Collections)</p>
            <p>Import an ERC721 Compatible Smart Contract to mint additional NFT</p>
            <p>When you import a collection to mine an NFT, it is not displayed under "My Collections". However, the NFT
              will regularly appear under "My NFTs" and will be usable as long as the ERC721 standard is supported.</p>
            <br>
          </template>

        </collapsible>

      </div>


      <div v-if="collection.address && toggle.newContract === true">
        <div class="card selected">
          <h2>Your new Contract Address</h2>
          <p>Save this address, you need it later to manage your NFT's on Chain</p>
          <b>{{ collection.address }}</b><br>
        </div>
      </div>

    </div>
    <div class="content disabled" v-else>
      <h2>Done! Go ahead to step 2!</h2>
    </div>


    <h1 class="title" id="minting">2) Create NFT</h1>
    <div class="content" v-if="collection.address">
      <h2>Mint a new NFT</h2>
      <h3>You can mint multiple NFT's into one Collection. The receiver address is getting the NFT to its wallet.</h3>
      <br>

      <h2>Upload your File</h2>
      <label for="nftFile" class="nftFileUploadBtn card ">
        <!--        <img v-if="NFT.meta.url" :src="NFT.meta.url" alt="">-->
        <span class="flex CenterCenter">
          <b class="noSpace" v-if="!NFT.meta.url">Click to Upload File:</b>
          <b v-else>{{ NFT.meta.name }}</b>
          <span class="flex">
            <span class="chip">Image: .jpg .png .gif </span>
          <span class="chip">Video: .mp4</span>
          <span class="chip">3D: .obj .glb (glTF)</span>
          </span>

        </span>
        <input type="file" class="nftFileUpload" id="nftFile" name="nftFile"
               @change="filesChange($event.target.name, $event.target.files)">
      </label>
      <br>

      <h2>Add the Meta to your NFT</h2>
      <input v-model="NFT.name" type="text" placeholder="NFT Name">
      <textarea rows="10" v-model="NFT.description" placeholder="NFT description + What do the NFT stand for? What is the real value? (e.g. Copyright, Commercial Rights....)"></textarea>
      <input type="url" v-model="singleNFTWebsite" placeholder="Dedicated Website URL for this NFT">
      <div class="card">
        <div class="receiverAddress">
          <input type="text" v-if="!toggle.editReceiverAddress" placeholder="Public key the recipient of the NFT"
                 :value="NftReceiverAddress" disabled>
          <input type="text" v-if="NftReceiverAddress.length < 0 || toggle.editReceiverAddress"
                 v-model="NftReceiverAddress" placeholder="Public key the recipient of the NFT">
          <span class="chip" @click="toggle.editReceiverAddress = !toggle.editReceiverAddress">Edit</span>
          <span @click="mintToMe" class="chip">My</span>
        </div>
      </div>
      <br>
      <div class="ctaWithChainCheck" v-if="!loading">
        <chain-switch-small></chain-switch-small>
        <button v-if="!NFT.meta.file || !NFT.name || !NFT.description || !NftReceiverAddress" class="ctaSized">
          Ready, set, mint!
        </button>
        <button v-else class="cta" @click="uploadToIpfs">Ready, set, mint!</button>
      </div>
      <loader v-if="loading"/>

    </div>
    <div v-else class="content disabled">
      <h2>Please create a collection first, and then you can mint New NFT's</h2>
    </div>


<!--    <h1 class="title">3) Your NFT served from IPFS</h1><br>-->

<!--    <div class="content container" v-if="URIMetaList.length > 0">-->
<!--      <nft-overview v-for="nft in URIMetaList" :inputNft="nft" :key="nft.id"/>-->
<!--    </div>-->
<!--    <div v-else class="content disabled">-->
<!--      <h2>Create some NFT's, afterwards they will appear here</h2>-->
<!--    </div>-->
  </div>
</template>

<script>
// My own Ethers API Wrapper Service named ChainAPI
import ChainApi from "../services/ChainApi";
// NFT.STORAGE API
import {File, NFTStorage} from 'nft.storage'
// import NftOverview from "../components/nft/nftOverview";
import BackendApi from "../services/BackendApi";
import axios from "axios";
import Api from "../services/Api";
// import AddPolygonChain from "../components/networkSettings/addMumbaiPolygonChain";
// import Changelly from "../components/external/changelly";
// import Collapsible from "../components/ui-components/collapsible";
import ChainSwitchSmall from "../components/networkSettings/chainSwitchSmall";
// import CollectionOverview from "../components/nft/collectionOverview";
import CollectionOverviewSmall from "../components/nft/collectionOverviewSmall";
import Loader from "../components/ui-components/loader";

// import ChainSymbolAddress from "../components/networkSettings/chainSymbolAddress";
// import NftDisplay from "../components/nft/nftDisplay";

const apiKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweEU3RjYzQWIyMzZBRmY4N2E2MzY4Rjk2YjFmNzZlZUIwMEU4YzE2ZDkiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTYzNjM2NzgwNjk3MywibmFtZSI6IkJhY2hlbG9yUHJvamVrdCJ9.E8VDhkKJI8hUBmD35ojHLLmVIs0_pEBw9y9ILGotiN8'
const client = new NFTStorage({token: apiKey})

// Collection Contract ABI and ByteCode for ChainAPI and Ethers.js
// @ is an alias to /src
const collectionContractABI = require('@/contracts/collection/collectionContractABI.json')
const collectionContractBytecode = require('@/contracts/collection/collectionContractBytecode.json')


export default {
  name: 'CreateNft',
  components: {

    Loader,
    CollectionOverviewSmall,
    // CollectionOverview,
    // ChainSymbolAddress,
    ChainSwitchSmall,
    // NftDisplay,
    // Collapsible,
    // Changelly,
    // AddPolygonChain,
    // NftOverview
    // HelloWorld
  },
  data() {
    return {
      // Libs
      ChainApi: ChainApi,
      loading: false,
      // Ethers Entities
      provider: null,
      signer: null,

      // State Toggle
      toggle: {
        newContract: true,
        moreSettings: false,
        isGenerativ: false,
        isLimited: false,
        isSelfMinting: false,
        hasRevenueShare: false,
        selectContract: false,
        importContract: false,
        editReceiverAddress: false,
      },

      //Revenue Share Details
      revenueShareAddress: '',

      // Collection Deployment
      collection: {
        name: '',
        symbol: '',
        description: '',
        baseUrl: '',
        contract: null,
        address: '',
        id: null,
        mintingLimit: null,
        priceSelfMint: null,
      },

      MyCollections: [],
      receivers: [],
      revenueSharePercent: 1,
      singleNFTWebsite: '',

      // Smart Contract Data
      abi: collectionContractABI,
      bytecode: collectionContractBytecode,


      //Minting Nft Information
      NftReceiverAddress: '',
      NFT: {
        name: '',
        description: '',
        meta: {}
      },

      // Effective Online NFT Data
      URI: '',
      URIMetaList: [],

      // Counters
      nftInContractCount: 0,

    }
  },
  mounted() {
    this.init()
  },
  activated() {
    this.init()
  },
  methods: {
    async init() {
      if (this.$route.params.contractAddress) {
        this.toggle.importContract = true
        this.collection.address = this.$route.params.contractAddress
        const response = await axios.get(Api.baseUrl + '/collections/byAddress/' + this.collection.address)
        this.collection.id = response.data[0].id
        await this.mintToMe()
      }
      await this.getReceivers()
      await this.getMyCollections()
    },

    async selectCollection(collection) {
      this.collection.address = collection.collectionAddress;
      console.log('is select true: ', this.collection.address === collection.collectionAddress)
      await this.importExisting()
    },
    async importExisting() {
      console.log('test' + this.collection.address)
      const response = await axios.get(Api.baseUrl + '/collections/byAddress/' + this.collection.address)
      this.collection.id = response.data[0].id
      // console.log(response.data[0].id)
      await this.mintToMe()
    },

    async mintToMe() {
      await this.getPermissions()
      this.NftReceiverAddress = await this.signer.getAddress();
    },

    async test() {
      await this.ChainApi.getCollectionNftCount(this.collection.contract)
    },

    async getPermissions() {
      this.provider = await this.ChainApi.connectMetaMask()
      this.signer = await this.ChainApi.createSigner(this.provider)
      this.NftReceiverAddress = await this.ChainApi.getPublicAddress(this.signer)
    },

    async deployContract() {
      if (this.toggle.newContract === true) {
        if (!this.collection.name || !this.collection.symbol) { //|| !this.collection.baseUrl
          alert('Please fill in the blank')
          return
        }
      } else {
        if (!this.collection.address) {
          alert('Please fill in the blank')
          return
        }

      }
      this.loading = true

      await this.getPermissions()

      const constructorArgs = [this.collection.name, this.collection.symbol, this.collection.baseUrl]
      try {
        this.collection.contract = await this.ChainApi.deployContract(this.abi, this.bytecode, this.signer, ...constructorArgs)
      } catch (e) {
        console.error(e)
        this.loading = false
        alert('Something unexpected happened. Please try again')
      }

      this.collection.address = this.collection.contract.contractAddress

      const resp = await this.provider.getNetwork()
      const chainId = resp.chainId

      const response = await BackendApi.createCollection(
          parseInt(localStorage.getItem('userId')),
          this.collection.address,
          this.collection.name,
          this.collection.symbol,
          this.collection.description,
          this.collection.website,
          this.toggle.isLimited,
          parseInt(this.collection.mintingLimit),
          this.toggle.isGenerativ,
          this.collection.baseUrl,
          this.toggle.isSelfMinting,
          parseInt(this.collection.priceSelfMint),
          this.toggle.hasRevenueShare,
          this.revenueSharePercent,
          this.revenueShareAddress,
          chainId
      )
      // alert(response)
      this.collection.id = response[0]
      this.loading = false
    },

    async uploadToIpfs() {
      this.loading = true
      if (!this.NFT) {
        return
      }

      if (!this.NFT.meta.file || !this.NFT.name || !this.NFT.description || !this.NftReceiverAddress) { //|| !this.collection.baseUrl
        alert('Please fill in the blank')
        return
      }

      let metadata = null
      try {
        metadata = await client.store({
          name: this.NFT.name,
          description: this.NFT.description,
          image: new File([this.NFT.meta.file], this.NFT.meta.name, {type: this.NFT.meta.type})
        })
      } catch (e) {
        console.error(e)
        this.loading = false
        alert('Something unexpected happened at uploading your NFT. Please try again')
      }
      // console.log(metadata)
      // console.log(metadata.url)
      this.URI = metadata.url


      await this.mintToAddress()
    },

    async mintToAddress() {
      // if contract is importet and not newly created we have no signer
      await this.getPermissions()

      try {
        // get contract if not saved already
        this.collection.contract = await this.ChainApi.getContract(this.collection.address, this.abi, this.signer)
      } catch (e) {
        console.error(e)
        this.loading = false
        alert("Couldn't mint NFT. Didn't reached Collection Contract on Chain. Please try again")
      }

      let tx = null
      try {
        // mint nft
        tx = await this.collection.contract.safeMint(this.NftReceiverAddress, this.URI)
      } catch (e) {
        console.error(e)
        this.loading = false
        alert("Couldn't mint NFT. Please try again")
      }
      // wait for the transaction to be mined
      const receipt = await tx.wait();

      const nftTokenID = receipt.events[0].args[2].toNumber()

      // after tx.wait() weil das nft erst dann fertig gemintet und gemined ist.
      await this.ChainApi.getCollectionNftCount(this.collection.contract)

      await this.saveNFTToDB(nftTokenID, receipt.transactionHash);

      this.NFT = {
        name: '',
        description: '',
        meta: {}
      }
    },


    async saveNFTToDB(tokenId, nftTx) {
      const nftURI = await this.collection.contract.tokenURI(tokenId)

      this.URIMetaList.push(nftURI)

      const resp = await this.provider.getNetwork()
      const chainId = resp.chainId
      const loggedinUserId = parseInt(localStorage.getItem('userId'))
      // await BackendApi.createNft(this.collection.id, tokenId, nftURI, 1)
      const nftDbID = await BackendApi.createNft(this.collection.id, tokenId, nftURI, nftTx, loggedinUserId, loggedinUserId, this.singleNFTWebsite, chainId)
      await this.$router.push('/nfts/' + nftDbID)
    },

    async getMyCollections() {
      axios.get(Api.baseUrl + "/myCollections").then(async (response) => {
        console.log(response.data);
        this.MyCollections = response.data
      }).catch(function (error) {
        console.error(error);
      });
    },

    async getReceivers() {
      axios.get(Api.baseUrl + "/receivers").then(async (response) => {
        console.log(response.data);
        this.receivers = response.data

      }).catch(function (error) {
        console.error(error);
      });
    },


    filesChange(fieldName, fileList) {
      if (!fileList.length) {
        return;
      }
      console.log(fileList)
      this.NFT.meta = {
        file: fileList[0],
        name: fileList[0].name,
        url: URL.createObjectURL(fileList[0]),
        type: fileList[0].type
      }
    },
  },
}
</script>
 <style scoped lang="scss">
@import 'src/styles/style.scss';

img {
  max-width: 100%;
  border-radius: 12px;
  margin-bottom: 16px;
}

.nftFileUpload {
  display: none;
}

.nftFileUploadBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.nftFileUploadBtn:hover {
  //border: 6px solid #42b983;
  box-shadow: 0 0 0px 6px #42b983;
}

.receiverAddress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;

  .chip {
    //width: 100%;
    //min-width: 50px;
    word-break: unset;
  }

  input {
    margin-bottom: 0;
  }
}


.content {
  margin-bottom: 100px;
}


.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 18px 18px;
  grid-auto-flow: row;

}



.nft_listing {
  //max-width: 100%;

  img {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 16px;
  }
}

.nft_upper_meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
