<template>
  <div class="card" @click="toggle = !toggle">
    <h2 style="margin:0;">
      <slot name="cta">{{ cta }}</slot>
    </h2>
    <div v-if="toggle" class="card selected">
      <slot>
      </slot>
    </div>

  </div>
</template>

<script>
export default {
  name: "collapsible",
  data() {
    return {
      toggle: false
    }
  }
}
</script>

<style scoped>

</style>