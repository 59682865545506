<template>
  <div class="demo">
    <etherJsDemo></etherJsDemo>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import etherJsDemo from "@/components/dev/etherJsDemo";
export default {
  name: 'DemoEtherJS',
  components: {
    etherJsDemo
  }
}
</script>
