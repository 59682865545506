<template>
  <div class="home">

    <h1 class="title">FAQ of this Website</h1>
    <div class="content">

      <collapsible>
        <template v-slot:cta>
          How to configure Polygon in Metamask
        </template>
        <template>
          https://docs.polygon.technology/docs/develop/metamask/config-polygon-on-metamask
        </template>
      </collapsible>

      <collapsible>
        <template v-slot:cta>
          How to Connect Metamask
        </template>
        <template>
          Content
        </template>
      </collapsible>

      <collapsible>
        <template v-slot:cta>
          Wich File Format for My NFT Data?
        </template>
        <template>
          Content
        </template>
      </collapsible>

      <collapsible>
        <template v-slot:cta>
          FAQ Suggestions
        </template>
        <template>
          Please send a Email to <a href="mailto:info@webentertainer.ch">info@webentertainer.ch</a>
        </template>
      </collapsible>
    </div>

  </div>
</template>

<script>
// My own Ethers API Wrapper Service named ChainAPI
import Collapsible from "../components/ui-components/collapsible";



export default {
  name: 'Help',
  components: {
    Collapsible,
    // NftOverview
    // HelloWorld
  },
  data() {
    return {
      crate: ""
    }
  },
  mounted() {
    this.init()
  },
  activated() {
    this.init()
  },
  methods: {
    async init() {

    },

  },
}
</script>
 <style scoped lang="scss">
@import 'src/styles/style.scss';

img {
  max-width: 100%;
  border-radius: 12px;
  margin-bottom: 16px;
}

.nftFileUpload {
  display: none;
}

.nftFileUploadBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.receiverAddress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}


.content {
  margin-bottom: 100px;
}


.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 18px 18px;
  grid-auto-flow: row;

}



.nft_listing {
  //max-width: 100%;

  img {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 16px;
  }
}

.nft_upper_meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
