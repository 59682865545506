<template>
  <div class="">
    <div class="content padding-top-in-dark-mode col2" style=" margin-top: 0; padding-top: 0;
    background: transparent;
    width:100%;">
      <div>


        <!--        <h1 class="highlight">-->
        <!--          <img src="@/assets/Flag_of_Switzerland.svg" alt="" style="height:70px; margin-right: 10px">-->
        <!--          Swiss NFT & Meta Market-->
        <!--        </h1>-->

        <h1 class="highlight">
          <!--          NFT Market for 3D Meta-->
          Swiss NFT Market
        </h1>

        <br>
        <p class="highlightText">
          <b>Create, sell and buy 3D, 2D and video art for the metaverse.</b>
          <br>
          <br>
          Use our platform with more than 5 Blockchains and
          easily donate money from your earnings to charity
          or share your profits with co-creators and business
          partners.
        </p>
        <br>
        <!--        <br>-->
        <router-link to="/market">
          <button style="max-width: 250px;" class="cta">View Market</button>
        </router-link>
        <!--        <router-link :to="'/collections/'+ this.collectionIDtoGet">-->
        <!--          <button style="max-width: 250px;" class="cta">View Collection</button>-->
        <!--        </router-link>-->
      </div>
      <div class="flexCenterCenter">
        <div v-if="listings.length > 0 || collection" class="featuredCollection">
          <collection-overview :data="collection"></collection-overview>
        </div>
        <div v-else class="flexCenterCenter">
          <img class="illustration" src="@/assets/no_data.svg" alt="">
          <h2>No NFT on sale</h2>
        </div>
      </div>
    </div>

    <div class="content">
      <h1>Never miss the Drop</h1>
      <br>
      <newsletter></newsletter>
      <br>
      <br>

    </div>


    <!--    <div class="content">-->
    <!--      <div class="flexCenterCenter">-->
    <!--        <h2 class="title">Create, Sell, Donate / Share, Buy</h2>-->
    <!--      </div>-->
    <!--      <br>-->
    <!--      <br>-->
    <!--      <div class="col5">-->
    <!--        <div class="card">Set up your wallet</div>-->
    <!--        <div class="card">Create your collection</div>-->
    <!--        <div class="card">Add your NFTs</div>-->
    <!--        <div class="card">List them for sale</div>-->
    <!--        <div class="card">Collect NFTs</div>-->
    <!--      </div>-->
    <!--    </div>-->


    <div class="content">
      <div class="flexCenterCenter">
        <h2 class="title">Advantages of our Swiss NFT platform</h2>
      </div>
      <div class="col5">
        <div class="card">
          <div class="iconBox">
            <div class="middleBtn">
              <img src="@/assets/accessible.svg" alt="">
            </div>
          </div>
          <h2>Accessible NFTs</h2>
          <p>Create ERC721 NFT with decentralized IPFS Media Storage</p>
        </div>
        <div class="card">
          <div class="iconBox">
            <div class="middleBtn">
              <img src="@/assets/nofee.svg" alt="">
            </div>
          </div>
          <h2>0% Fee on Minting</h2>
          <p>We do not take a fee for creating NFT over our platform</p>

        </div>
        <div class="card">
          <div class="iconBox">
            <div class="middleBtn">
              <img src="@/assets/multichain.svg" alt="">
            </div>
          </div>
          <h2>Multichain</h2>
          <p>Choose your preferred Blockchain to mint, sell and buy</p>

        </div>
        <div class="card">
          <div class="iconBox">
            <div class="middleBtn">
              <img src="@/assets/proof.svg" alt="">
            </div>
          </div>
          <h2>NFT Proof</h2>
          <p>Use our NFT Proof tools to check the NFT you want to buy, before you buy it.</p>

          <!--          <p>Use our NFT Proof tools to check the NFT you want to buy, before you buy it. - Verify on Chain, Verify-->
          <!--            decentralized Hosting, Mirror your NFT on IPFS to secure it</p>-->
        </div>
        <div class="card">
          <div class="iconBox">
            <div class="middleBtn">
              <img src="@/assets/donate.svg" alt="">
            </div>
          </div>
          <h2>Donate / Share</h2>
          <p>Share parts of your revenue to Co-Creators or business partners, or Donate it to charity</p>

        </div>
      </div>
    </div>

    <!--    <div class="content">-->
    <!--      <div class="flexCenterCenter">-->
    <!--        <h2 class="title">Get started with NFTs</h2>-->
    <!--      </div>-->
    <!--      <div class="col5">-->
    <!--        <div class="card">-->
    <!--          <h2>1) Set up wallet</h2>-->
    <!--          <button class="cta">Install MetaMask</button>-->
    <!--        </div>-->
    <!--        <div class="card">-->
    <!--          <h2>2) Create collection</h2>-->
    <!--          <button class="cta">-></button>-->
    <!--        </div>-->
    <!--        <div class="card">-->
    <!--          <h2>3) Mint NFTs</h2>-->
    <!--          <button class="cta">-></button>-->
    <!--        </div>-->
    <!--        <div class="card">-->
    <!--          <h2>4) List for sale</h2>-->
    <!--          <button class="cta">-></button>-->
    <!--        </div>-->
    <!--        <div class="card">-->
    <!--          <h2>5) Collect NFTs</h2>-->
    <!--          <button class="cta">-></button>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <div class="content">-->
    <!--      <div class="flexCenterCenter">-->
    <!--        <h2 class="title">Resources for getting started</h2>-->
    <!--      </div>-->
    <!--      <br>-->
    <!--      <br>-->
    <!--      <div class="col2">-->
    <!--        <div class="col5" style="display: flex; flex-direction: column; gap: 30px;">-->
    <!--          <div>What is an NFT</div>-->
    <!--          <div>What is an NFT Collection</div>-->
    <!--          <div>How to Buy NFT</div>-->
    <!--          <div>Which File Formats</div>-->
    <!--          <div>What is the Meta verse</div>-->
    <!--        </div>-->
    <!--        <div class="iconBox" style="-->
    <!--            height: 100%;-->
    <!--            border-radius: 18px;-->
    <!--            background-color: #42b983;-->
    <!--        ">-->
    <!--          <div class="middleBtn">-->
    <!--            <img src="@/assets/donate.svg" alt="">-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--    </div>-->


    <div class="content">
      <div class="flexCenterCenter">
        <!--        <h2 class="title">Never miss the Drop</h2>-->
      </div>
      <h1>Keep updated in the Crypto Verse</h1><br>
      <p class="">
        (we are not affiliated with any news media we display here.)
      </p>
      <br>
      <br>
      <News/>
    </div>

    <!--    <img alt="Vue logo" src="../assets/logo.png">-->
    <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
</template>

<script>
import axios from "axios";
import Api from "../services/Api";
// import ListingOverview from "../components/nft/listingOverview";
import News from "./News";
import CollectionOverview from "../components/nft/collectionOverview";
import Newsletter from "../components/ui-components/newsletter";
// import NftPreview from "../components/nft/nftPreview";
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
export default {
  name: 'Home',
  components: {
    Newsletter,
    CollectionOverview,
    News,
    // NftPreview,
    // ListingOverview,
    // HelloWorld
  },
  data() {
    return {
      listings: [],
      collection: null,
      collectionIDtoGet: 7,
      nfts: []
    }
  },
  mounted() {
    this.init()
  },
  activated() {
    this.init()
  },
  methods: {
    async init() {
      await this.getCollection(this.collectionIDtoGet)
    },
    async getCollection(id) {
      const response = await axios.get(Api.baseUrl + "/collections/" + id)
      this.collection = response.data[0]
      //
      // const responseNFT = await axios.get(Api.baseUrl + '/nftsByCollection/' + this.collection.id)
      // this.nfts = responseNFT.data


      // const responseListings = await axios.get(Api.baseUrl + '/listingsByCollection/' + this.collection.id)

      // let listingsModel = responseListings.data
      //
      // for (let listing of listingsModel) {
      //   if (listing.nft_id) {
      //     const response2 = await axios.get(Api.baseUrl + "/nfts/" + listing.nft_id)
      //     listing.nft = response2.data[0]
      //     // console.log(response2)
      //   }
      // }

      //
      // if (this.listings.length === 0) {
      //   this.tab = 2
      // }


      // this.listings = listingsModel


      // console.log(response2)
      // this.nft.ipfs = await IpfsAPI.getNFTMeta(this.nft.nftURI)
      //
      // await this.getUser(this.collection.user_id);
    },

    // async getListings() {
    //
    //   const response = await axios.get(Api.baseUrl + "/listings")
    //   // console.log(response.data);
    //   // this.listings = response.data
    //
    //   let listingsModel = response.data
    //
    //   for (let listing of listingsModel) {
    //     if (listing.nft_id) {
    //       const response2 = await axios.get(Api.baseUrl + "/nfts/" + listing.nft_id)
    //       listing.nft = response2.data[0]
    //       // console.log(response2)
    //     }
    //   }
    //
    //   this.listings = listingsModel
    //
    // }
  },
}
</script>
<style scoped lang="scss">
@import 'src/styles/style.scss';


.featuredCollection {
  width: 350px;
}

.highlight {
  font-size: 100px;
}

.highlightText {
  font-size: 22px;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px 18px;
  grid-auto-flow: row;
}

//
//.featuredCollection .container {
//  display: grid;
//  grid-template-columns: 1fr 1fr!important;
//  gap: 18px 18px;
//  grid-auto-flow: row;
//}


@media screen and (max-width: 992px) {
  .featuredCollection {
    width: 100%;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
}


.nft_listing {
  //max-width: 100%;

  img {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 16px;
  }
}

.nft_upper_meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 900px) {
  h1.highlight {
    font-size: 50px !important;
  }
  .contentWrapper {
    padding: 20px !important;
  }
  .content {
    width: 100%;
    min-width: unset;
    max-width: 100vw;
  }
}
</style>
