<template>
  <div class="card artist artistOverview" @click="visitUser">

    <img class="artistImage"
         :title="data.publicAddress"
         :alt="data.publicAddress"
         :src="identicon"><br>
    <h2 class="title" :title="data.publicAddress">{{ data.name }}</h2>
    <p class="description">{{ data.bio }}</p>

  </div>
</template>

<script>

import ChainApi from "../../services/ChainApi";

export default {
  name: "artistOverview",
  components: {},
  props: {
    data: Object
  },
  data() {
    return {
      ChainApi: ChainApi,
      identicon: null,
      loading: false,
      error: null,
    }
  },
  activated() {
    this.init()
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      await this.getIdenticon(this.data.publicAddress)
    },
    async getIdenticon(publicAddress) {
      this.identicon = await ChainApi.getIdenticon(publicAddress);
    },
    visitUser() {
      this.$router.push('/users/' + this.data.id)
    }
  },
}
</script>

 <style scoped lang="scss">
@import 'src/styles/style.scss';

.artistOverview {
  min-height: 450px;
}

.title {
  min-height: 40px;
}

.description {
  min-height: 80px;
}


.artist {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.card {
  background-color: #fff;
}

.artistImage {
  //max-width: 50px;
  width: 124px;
  height: 124px;
  border-radius: 50%;
}


</style>