<template>
  <div>
    <!--    <button @click="switchChains">Add/switch Polygon Network on MetaMask</button>-->

    <div class="switch-field">
      <input type="radio" id="radio-Polygon" name="switch-chain" :value="true" checked/>
      <label @click="switchChains(polygonMumbai)" for="radio-Polygon">Polygon Testnet Mumbai</label>
      <input type="radio" id="radio-Ethereum" name="switch-chain" value="no"/>
      <label @click="switchChains(ethereumHomestead)" for="radio-Ethereum">Ethereum Mainnet</label>
      <input type="radio" id="radio-Binance" name="switch-chain" value="no"/>
      <label @click="switchChains(binanceSmartChain)" for="radio-Binance">Binance Smart Chain Mainnet</label>
    </div>
    <!--    <a href="https://networklist-org.vercel.app/">-->
    <!--      <span>Add Matic network manually. visit this site and connect and add your desired network</span>-->
    <!--    </a>-->
  </div>
</template>

<script>
// My own Ethers API Wrapper Service named ChainAPI
// import Collapsible from "../components/ui-components/collapsible";


export default {
  name: 'addPolygonChain',
  components: {
    // Collapsible,
    // NftOverview
    // HelloWorld
  },
  data() {
    return {
      ethereum: null,
      polygonMumbai: {
        chainId: this.toHex(80001),
        chainName: "Polygon Testnet Mumbai",
        // symbol: 'MATIC',
        nativeCurrency: {
          // name: 'MATIC',
          symbol: 'MATIC', // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
        blockExplorerUrls: ['https://mumbai.polygonscan.com/'],

      },
      ethereumHomestead: {
        name: "Ethereum Mainnet",
        chain: "ETH",
        network: "mainnet",
        icon: "ethereum",
        rpc: [
          // "https://mainnet.infura.io/v3/${INFURA_API_KEY}",
          // "wss://mainnet.infura.io/ws/v3/${INFURA_API_KEY}",
          "https://api.mycryptoapi.com/eth",
          "https://cloudflare-eth.com"
        ],
        faucets: [],
        nativeCurrency: {
          "name": "Ether",
          "symbol": "ETH",
          "decimals": 18
        },
        infoURL: "https://ethereum.org",
        shortName: "eth",
        chainId: this.toHex(1),
        networkId: 1,
        slip44: 60,
        ens: {
          "registry": "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e"
        },
        explorers: [
          {
            "name": "etherscan",
            "url": "https://etherscan.io",
            "standard": "EIP3091"
          }
        ]
      },
      binanceSmartChain: {

        chainId: this.toHex(56),
        chainName: "Binance Smart Chain Mainnet",
        // symbol: 'MATIC',
        nativeCurrency: {
          "name": "Binance Chain Native Token",
          "symbol": "BNB",
          "decimals": 18
        },
        rpcUrls: ["https://bsc-dataseed1.binance.org",
          "https://bsc-dataseed2.binance.org",
          "https://bsc-dataseed3.binance.org",
          "https://bsc-dataseed4.binance.org",
          "https://bsc-dataseed1.defibit.io",
          "https://bsc-dataseed2.defibit.io",
          "https://bsc-dataseed3.defibit.io",
          "https://bsc-dataseed4.defibit.io",
          "https://bsc-dataseed1.ninicoin.io",
          "https://bsc-dataseed2.ninicoin.io",
          "https://bsc-dataseed3.ninicoin.io",
          "https://bsc-dataseed4.ninicoin.io",
          "wss://bsc-ws-node.nariox.org"],
        blockExplorerUrls: ["https://bscscan.com/"],
      },
    }
  },
  mounted() {
    this.init()
  },
  activated() {
    this.init()
  },
  methods: {
    async init() {
      this.ethereum = window.ethereum;
    },

    toHex(num) {
      return '0x' + num.toString(16)
    },

    async switchChains(chainData) {

      try {
        await this.ethereum.request({
          method: 'wallet_switchEthereumChain',
          // hier hex string der chainId holen und mit "0x" prefixen : https://string-functions.com/string-hex.aspx
          params: [{chainId: chainData.chainId}], // matic main net
          // params: [{chainId: '0x80001'}], // try testnet matic
          // params: [{chainId: '0xf00'}], // demo
        });
      } catch (switchError) {
        console.log('switchError', switchError)
        // This error code indicates that the networkSettings has not been added to MetaMask.
        if (switchError.code === 4902) {
          // Quelle für EVM CHain date Parameter zum Adden
          // https://chainid.network/chains.json
          try {
            await this.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [chainData],
            });
          } catch (addError) {
            // handle "add" error
            console.error('addError', addError)
          }
        }
        // handle other "switch" errors
      }
    },

  },
}
</script>
 <style scoped lang="scss">
@import 'src/styles/style.scss';

img {
  max-width: 100%;
  border-radius: 12px;
  margin-bottom: 16px;
}

.nftFileUpload {
  display: none;
}

.nftFileUploadBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.receiverAddress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}


.content {
  margin-bottom: 100px;
}


.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 18px 18px;
  grid-auto-flow: row;

}



.nft_listing {
  //max-width: 100%;

  img {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 16px;
  }
}

.nft_upper_meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
