<template>
  <div class="DeployTraderContract">

    <h1 class="title">Deploy Trader Contract</h1>
    <div class="content">
      <button @click="deployContract">Deploy Trader Contract</button>
    </div>
    <Loader v-if="loading"/>

    <div class="card" v-if="this.collection.contract">
      <input type="text" v-model="this.collection.contract.contractAddress" disabled>
      <input type="text" v-model="chainId" disabled>
    </div>

  </div>
</template>

<script>
import Loader from "../components/ui-components/loader";
import ChainApi from "../services/ChainApi";

// Collection Contract ABI and ByteCode for ChainAPI and Ethers.js
const traderContractABI = require('@/contracts/trader/traderContractABI.json')
const traderContractBytecode = require('@/contracts/trader/traderContractBytecode.json')

export default {
  name: 'DeployTraderContract',
  components: {
    Loader
  },
  data() {
    return {
      // Libs
      ChainApi: ChainApi,
      loading: false,
      // Ethers Entities
      provider: null,
      signer: null,
      chainId: null,
      // Collection Deployment
      collection: {
        name: '',
        symbol: '',
        description: '',
        baseUrl: '',
        contract: null,
        address: '',
        id: null,
        mintingLimit: null,
        priceSelfMint: null,
      },

      // Smart Contract Data
      abi: traderContractABI,
      bytecode: traderContractBytecode,

    }
  },
  mounted() {
    this.init()
  },
  // activated() {
  //   this.init()
  // },
  methods: {
    async init() {

    },

    async getPermissions() {
      this.provider = await this.ChainApi.connectMetaMask()
      this.signer = await this.ChainApi.createSigner(this.provider)
      this.NftReceiverAddress = await this.ChainApi.getPublicAddress(this.signer)
    },

    async deployContract() {
      // if (this.toggle.newContract === true) {
      //   if (!this.collection.name || !this.collection.symbol) { //|| !this.collection.baseUrl
      //     alert('Please fill in the blank')
      //     return
      //   }
      // } else {
      //   if (!this.collection.address) {
      //     alert('Please fill in the blank')
      //     return
      //   }
      //
      // }
      this.loading = true

      await this.getPermissions()

      // const constructorArgs = [this.collection.name, this.collection.symbol, this.collection.baseUrl]
      const constructorArgs = []
      try {
        this.collection.contract = await this.ChainApi.deployContract(this.abi, this.bytecode, this.signer, ...constructorArgs)
      } catch (e) {
        console.error(e)
        this.loading = false
        alert('Something unexpected happened. Please try again')
      }

      console.log(this.collection.contract.contractAddress)

      this.collection.address = this.collection.contract.contractAddress

      const resp = await this.provider.getNetwork()
      this.chainId = resp.chainId


      this.loading = false
    },


  },
}
</script>
 <style scoped lang="scss">
@import 'src/styles/style.scss';

img {
  max-width: 100%;
  border-radius: 12px;
  margin-bottom: 16px;
}

.nftFileUpload {
  display: none;
}

.nftFileUploadBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.receiverAddress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}


.content {
  margin-bottom: 100px;
}


.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 18px 18px;
  grid-auto-flow: row;

}



.nft_listing {
  //max-width: 100%;

  img {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 16px;
  }
}

.nft_upper_meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
