<template>
  <div class="newsletter selected" style="max-width: 100%">
    <div class="col2" style="gap: 60px">
      <div>
        <h1>Decentralized Multi Chain NFT Marketplace</h1><br>
        <p>We Are decentralized, Switzerland based Multi-Chain NFT Marketplace For Imagery, Video and 3D Art.</p>
        <p>Our goal is to create an easy, trustworthy environment for artists and collectors. Our NFT platform produces
          standardized NFT collections so you can use it anywhere. Our NFT can be used in decentralized art galleries,
          Metaverse worlds, tickets to Metaverse clubs, or whatever your imagination allows.</p>
        <p>We do not charge for mining NFT (On all supported blockchains).</p>
        <br>
        <br>
      </div>
      <div class="card flexCenterCenter no-shadow" style="max-width: 500px; ">
        <h2 class="title" style="text-align:center">Community, Support and Friends!</h2>
        <div class="flex">
          <!--          <input type="text" placeholder="info@gmail.com">-->
          <a target="_blank" href="https://discord.gg/qgkvcQ8S">
            <button class="cta">Join us on Discord</button>
          </a>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "newsletter"
}
</script>

<style scoped lang="scss">
@import 'src/styles/style.scss';


.newsletter {
  .card .title {
  color: var(--black)
  }
  //width: 50vw;
  //min-width: 600px;
  //max-width: 50vw;
  width: 100%;
  background-color: white;
  margin-top: $big * 1.5;
  border-radius: $radius-big;
  overflow-x: auto;

  padding: 20px !important
}

</style>