<template>
  <div class="home" v-if="connectedNetwork">

    <div v-if="!ChainApi.getTraderContractDeployed.includes(connectedNetwork.chainId)">
      <h1 class="title">Select NFT to sell</h1>
      <div class="content selectedBorder">
        <h2> We are sorry, we have not implemented our Trader SmartContract on the blockchain you are connected to.
        </h2>
        <p>
          In networks that don't have a trading contract yet, you can create NFT collections and NFT, but you can't sell
          them through our platform. Use a third party provider, or send us a request, if there is enough interest from
          the community we can expand our trading network.
        </p>
        <br>
        <div class="flexCenterCenter">
          <chain-switch-small/>
        </div>
      </div>
    </div>
    <div v-else>
      <h1 class="title">Select NFT to Transfer</h1>

      <div class="content">

        <h2>Choose your way to proceed</h2>

        <br>

        <div class="switch-field">
          <input type="radio" id="radio-one-select" name="switch-one-select" :value="true" checked/>
          <label @click="toggle.selectNFT = true" for="radio-one-select">Select one of your NFT</label>
          <input type="radio" id="radio-two-select" name="switch-one-select" value="no"/>
          <label @click="toggle.selectNFT = false" for="radio-two-select">Import NFT (External ERC721)</label>
        </div>
        <br>

        <h2>[[ get nft witch are not a Listing and have the current CainID as param]]</h2>
        <div v-if="toggle.selectNFT && myNfts">

          <div class="card" v-if="myNfts.length > 0">
            <h2>Select your NFT you want to sell</h2>
            <div class="nftSelectorBIG">
              <div v-for="nft of myNfts"
                   :key="nft.id"
                   @click="
                 toggle.preview = false;
                 selectedNft = nft;
                 step = 1;
                 toggle.preview = true;"
                   class="nftItemWrapper">
                <!--            <div class="selectorItemMedia">-->
                <nft-overview-small v-bind:class="{selectedBorder: nft === selectedNft}" :input-nft="nft"
                                    style="width: 220px"/>
              </div>
            </div>
          </div>
          <div v-else class="card flexCenterCenter">
            <img class="illustration" src="@/assets/no_data.svg" alt="">
            <h2>Please create or import n NFT first, then sell it owner this page</h2>
          </div>
        </div>


        <div v-if="!toggle.selectNFT" class="card">
          <h2>Import NFT</h2>
          <p>Make sure, your MetaMask is connected to the blockchain the nft lives in. Be sure you only import
            compatible ERC 721 Contracts!</p>

          <input v-model="collection.collectionAddress" type="text" placeholder="Collection Contract Address">
          <input v-model="tokenID" type="text" placeholder="NFT TokenID within Collection">
          <div class="ctaWithChainCheck">
            <chain-switch-small :token="collection.collectionAddress + '?a=' + tokenID "/>
            <button class="cta" @click="step = 1">Add NFT Listing</button>
          </div>
        </div>

      </div>

      <h1 class="title" id="configure">Choose new Owner</h1>

      <div v-if="step === 1" class="content">
        <div class="col2">
          <div class="card">

            <h2>Revenue Sharing settings</h2>

            <select name="" id="" v-model="newOwnerAddress">
              <option value="" selected>Custom Address</option>
              <option v-for="org in receivers" :key="org.id" :value="org.publicAddress">{{ org.name }}</option>
            </select>

            <div class="flex noWrap">
              <input type="text" v-if="!toggle.editReceiverAddress" placeholder="Recipient address"
                     :value="newOwnerAddress" disabled>
              <input type="text" v-if="newOwnerAddress.length < 0 || toggle.editReceiverAddress"
                     v-model="newOwnerAddress" placeholder="Recipient address">
              <button class="chip noWordBreak" @click="toggle.editReceiverAddress = !toggle.editReceiverAddress">Edit
              </button>
            </div>

            <label for="hasRevenueShare" class="flex">
              <input class="checkBox" id="hasRevenueShare" type="checkbox" v-model="toggle.hasRevenueShare">

              <span class="inputHelp">
                  I understand that this function transfers ownership on the blockchain, this is unstoppable and reversible.
                  There are no fees, except for the network fees for the transfer.
                </span>
            </label>


            <div v-if="!loading">
              <button class="cta" @click="getTradingPermissions" v-if="newOwnerAddress">Transfer NFT</button>
              <button class="ctaSized" v-else>Transfer NFT <b>{{ selectedNft.chainId }}</b></button>
              <p>[[Add Chain-Check wie beim kauf]]</p>
            </div>
            <loader v-if="loading"/>
          </div>


          <div class="card">
            <h2>Listing Preview</h2>
            <nft-overview v-if="toggle.preview" :inputNft="selectedNft"/>
          </div>
        </div>

      </div>
      <div class="content disabled" v-else>
        <h2>Please select a NFT first</h2>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import Api from "../services/Api";
import IpfsAPI from "../services/IpfsAPI";
import ChainApi from "../services/ChainApi";
// import NftDisplay from "../components/nft/nftDisplay";
import ChainSwitchSmall from "../components/networkSettings/chainSwitchSmall";
import NftOverviewSmall from "../components/nft/nftOverviewSmall";
// import NftOverview from "../components/nft/nftOverview";
// import NftPreview from "../components/nft/nftPreview";
import Loader from "../components/ui-components/loader";
import NftOverview from "../components/nft/nftOverview";

const collectionContractABI = require('@/contracts/collection/collectionContractABI.json')
const collectionContractBytecode = require('@/contracts/collection/collectionContractBytecode.json')

const traderContractABI = require('@/contracts/trader/traderContractABI.json')
const traderContractBytecode = require('@/contracts/trader/traderContractBytecode.json')


// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
export default {
  name: 'TransferNft',
  components: {
    NftOverview,
    Loader,
    // NftPreview,
    // NftOverview,
    NftOverviewSmall,
    ChainSwitchSmall,
    // NftDisplay
    // NftOverview
    // HelloWorld
  },
  data() {
    return {
      ChainApi: ChainApi,

      collectionContractABI: collectionContractABI,
      collectionContractBytecode: collectionContractBytecode,

      traderContractABI: traderContractABI,
      traderContractBytecode: traderContractBytecode,

      newOwnerAddress: '',

      revenueShareAddress: '',
      revenueSharePercent: 1,

      step: 0,

      toggle: {
        preview: false,
        selectNFT: true,
        newContract: false,
        moreSettings: false,
        isGenerativ: false,
        isLimited: false,
        isSelfMinting: false,
        hasRevenueShare: false,
        importContract: false,
        editReceiverAddress: false,
      },

      loading: false,

      myNfts: null,
      receivers: [],
      selectedNft: null,

      provider: null,
      signer: null,
      MyAddress: null,

      collection: {},
      traderContractAddress: '',
      directPayoutBool: true,
      connectedNetwork: null,
      NftCollectionOfNft: null,
      tokenID: null,
      priceInETH: null,
      priceInWei: null,
      TraderContract: null,
    }
  },
  mounted() {
    this.init()
  },
  // activated() {
  //   this.init()
  // },
  methods: {

    async convertETHtoWei() {
      this.priceInWei = null
      this.priceInWei = await ChainApi.convertEthInWei(this.priceInETH)
    },
    async init() {
      await this.getMyNfts()
      await this.getReceivers()
      await this.getPermissions()
    },

    async getPermissions() {
      this.loading = true
      this.provider = await this.ChainApi.connectMetaMask()
      this.connectedNetwork = await this.provider.getNetwork()
      console.log(this.connectedNetwork.chainId)
      this.traderContractAddress = this.ChainApi.getTraderContractByChainId(this.connectedNetwork.chainId)

      try {
        this.signer = await this.ChainApi.createSigner(this.provider)
      } catch (e) {
        console.error(e)
        this.loading = false
        alert('Something unexpected happened. Please try again')
      }
      try {
        this.MyAddress = await this.ChainApi.getPublicAddress(this.signer)
        this.loading = false
      } catch (e) {
        console.error(e)
        this.loading = false
        alert('Something unexpected happened. Please try again')
      }

    },

    async getTradingPermissions() {
      this.loading = true
      try {
        const response = await axios.get(Api.baseUrl + "/collections/" + this.selectedNft.collection_id)
        this.collection = response.data[0]
      } catch (e) {
        console.error(e)
        this.loading = false
        alert('Something unexpected happened. Please try again')
      }
      console.log(this.collection)

      try {
        // Get NFT Collection
        this.NftCollectionOfNft = await this.ChainApi.getContract(
            this.collection.collectionAddress,
            this.collectionContractABI,
            this.signer)
        console.log('NFT Collection Contract: ', this.NftCollectionOfNft)
      } catch (e) {
        console.error(e)
        this.loading = false
        alert('Something unexpected happened. Please try again')
      }

      try {
        // Get Trader Contract
        this.TraderContract = await this.ChainApi.getContract(
            this.traderContractAddress,
            this.traderContractABI,
            this.signer)
        console.log('Trader Contract: ', this.TraderContract)
      } catch (e) {
        console.error(e)
        this.loading = false
        alert('Something unexpected happened. Please try again')
      }

      this.tokenID = parseInt(this.selectedNft.nftTokenId)
      console.log(this.tokenID)

      try {
        // User muss Bewilligen das TraderContract NFT verwalten darf
        // const ApprovalTx = await this.NftCollectionOfNft.setApprovalForAll(this.traderContractAddress, true)
        const ApprovalTx = await this.NftCollectionOfNft.approve(this.traderContractAddress, this.tokenID)
        const ApprovalReceipt = await ApprovalTx.wait(); // wait for the transaction to be mined
        console.log('SetApprovalForAll receipt', ApprovalReceipt)
      } catch (e) {
        console.error(e)
        this.loading = false
        alert('Something unexpected happened. Please try again')
      }

      try {
        // Check If Trader has all Permission to Sell / Move / Nft
        let responseIsApproved = await this.NftCollectionOfNft.getApproved(this.tokenID)
        console.log('is approved? - Approved Address: ', responseIsApproved)
      } catch (e) {
        console.error(e)
        this.loading = false
        alert('Something unexpected happened. Please try again')
      }
      // get TokenID (in Smartcontract) of Selectet NFT


      // Add Listing
      try {
        await this.convertETHtoWei()
        const weiBigNumber = await this.ChainApi.getBignumberWei(this.priceInWei)

        const AddListingTx = await this.TraderContract.addListing(weiBigNumber, this.collection.collectionAddress, this.tokenID, this.directPayoutBool)
        const AddListingReceipt = await AddListingTx.wait(); // wait for the transaction to be mined
        console.log('AddListingReceipt receipt', AddListingReceipt)
      } catch (e) {
        console.error(e)
        this.loading = false
        alert('Something unexpected happened. Please try again')
      }

      let respListing = null
      try {
        // Get Listing (testen ob es geklappt hat)
        respListing = await this.TraderContract.listings(this.collection.collectionAddress, this.tokenID)
      } catch (e) {
        console.error(e)
        this.loading = false
        alert('Something unexpected happened. Please try again')
      }
      console.log('------------')
      console.log(respListing)
      console.log('------------')

      if (respListing) {
        await this.createListing()
      }

    },

    async createListing() {

      if (!this.selectedNft) {
        return
      }
      const resp = await this.provider.getNetwork()
      const chainId = resp.chainId
      axios.post(Api.baseUrl + "/listings", {
        user_id: parseInt(localStorage.getItem('userId')),
        chainId: chainId,
        collection_id: this.selectedNft.collection_id,
        nft_id: this.selectedNft.id,
        type: 'price',
        price: this.priceInWei,
        category: 'generic'
      }).then((response) => {
        this.loading = false
        alert('listing is added!')
        console.log(response.data);
        // this.step++
        this.$router.push('/market/')
      }).catch(function (error) {
        console.error(error);
        this.loading = false
        alert("Something unexpected happened. NFT Created on cain, but couldn't save to our Database Please try again")
      });
    },

    async getMyNfts() {
      axios.get(Api.baseUrl + "/myNfts").then(async (response) => {
        console.log('myNfts', response.data);
        let nfts = response.data

        for await (const nft of nfts) {
          await this.getNFTMeta(nft)
        }

        this.myNfts = nfts
        console.log('myNfts', response.data);
      }).catch(function (error) {
        console.error(error);
      });
    },

    async getReceivers() {
      axios.get(Api.baseUrl + "/receivers").then(async (response) => {
        console.log(response.data);
        this.receivers = response.data

      }).catch(function (error) {
        console.error(error);
      });
    },

    async getNFTMeta(nft) {
      const meta = await IpfsAPI.getNFTMeta(nft.nftURI)
      nft.ipfs = meta

      return nft
    }
  },
}
</script>
<style scoped lang="scss">
@import 'src/styles/style.scss';


.nftPreviewImg {
  width: 100%;
  border-radius: 16px;
  margin-bottom: 16px;
}

.nftSelector {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  max-height: 260px;
  overflow: auto;
  margin-bottom: 16px;

  img, .selectorItemMedia {
    border-radius: 4px;
    width: 20%;
  }
}

.nftSelectorBIG {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 16px;
  //max-height: 260px;
  overflow: auto;
  margin-bottom: 16px;

  .nftItemWrapper {
    //height: 300px;
  }

  .selectorItemMedia {
    border-radius: 4px;
    width: 200px;

  }
}


.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 18px 18px;
  grid-auto-flow: row;

}


.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}


.nft_listing {
  //max-width: 100%;

  img {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 16px;
  }
}

.nft_upper_meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  margin-bottom: 100px;
}


.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 18px 18px;
  grid-auto-flow: row;

}
</style>
