<template>
  <div class="card artist">
    <div class="container">
      <!--  -->
      <!--      <nft-preview v-for="nft of NFTs" :input-nft="nft" :key="nft.id"></nft-preview>-->
      <div class="nftMediaWrapper" ref="nftMediaWrapper" v-for="nft of NFTs" :key="nft.id">
        <!--        <div style="height:100%; margin-top: -100%; width: 100%;">-->
        <nft-display class="nftMedia" :width="nftPrevWidth" :height="nftPrevHeight" v-if="nft.meta && nftPrevWidth"
                     :mediaURL="nft.meta.image"></nft-display>
        <!--        </div>-->

      </div>
    </div>
<!--    <div @click="viewListing">-->
      <!--    {{ NFTs }}-->
      <h2 :title="data.collectionAddress">{{ data.collectionName }}</h2>
      <!--    <p>{{ data.collectionSymbol }}</p>-->
<!--      <div><p>{{ data.collectionDescription | truncate(100) }}</p></div>-->
<!--    </div>-->
    <button class="cta">Select</button><!---->
    <span class="symbolAndAddressWrapper">

<!--      <input type="text" :value="data.collectionAddress" disabled>-->
      <chain-symbol-address :chain-id="data.chainId" :address="data.collectionAddress"/>
    </span>

  </div>
</template>

<script>
import IpfsAPI from "../../services/IpfsAPI";
import ChainApi from "../../services/ChainApi";
import axios from "axios";
import Api from "../../services/Api";
// import NftPreview from "./nftPreview";
import ChainSymbolAddress from "../networkSettings/chainSymbolAddress";
import NftDisplay from "./nftDisplay";

export default {
  name: "collectionOverviewSmall",
  components: {
    NftDisplay, ChainSymbolAddress,
    // NftPreview
  },
  props: {
    data: Object
  },
  computed: {},
  data() {
    return {
      IpfsAPI: IpfsAPI,
      ChainApi: ChainApi,
      identicon: null,
      NFTs: [],
      loading: false,
      error: null,

      nftPrevHeight: null,
      nftPrevWidth: null,
    }
  },
  activated() {
    this.init()

  },
  mounted() {
    this.init()

  },
  methods: {
    prevHeight() {

      const wrappers = this.$refs.nftMediaWrapper
      if (wrappers) {
        wrappers.forEach((wrapper) => {
          console.log(wrapper.clientHeight, wrapper.clientWidth)
          this.nftPrevHeight = wrapper.clientHeight
          this.nftPrevWidth = wrapper.clientWidth
        })


      }

      // const height  = this.$refs.nftMediaWrapper.clientHeight
      // const width = this.$refs.nftMediaWrapperlet.clientWidth;
      // console.log(height, width)
      // return {height: height, width: width}

    },
    async init() {
      await this.getIdenticon(this.data.collectionAddress)
      await this.getNFTs()
      this.prevHeight()
    },

    viewListing() {
      this.$router.push('/collections/' + this.data.id)
    },

    async getIdenticon(publicAddress) {
      this.identicon = await ChainApi.getIdenticon(publicAddress);
    },

    async getNFTs() {
      const response = await axios.get(Api.baseUrl + '/nftsByCollection/' + this.data.id)
      this.NFTs = response.data

      for (const nft of this.NFTs) {
        nft.meta = await this.IpfsAPI.getNFTMeta(nft.nftURI)
      }

      // auf 4 Previevs limitieren (sollte noch serverseitig möglich sein)
      this.NFTs = this.NFTs.slice(0, 4);
      // console.log(this.NFTs)
    }
  },
}
</script>

 <style scoped lang="scss">
@import 'src/styles/style.scss';

.symbolAndAddressWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 18px;

  input {
    margin-bottom: 0 !important;
  }
}


.nftMediaWrapper {
  padding: 8px;
  //height: 100%;
  //width: 300px;
  width: 100%;
  min-width: 100%;
  position: relative;
  height: 0;
  overflow: hidden;
  padding-top: calc(100%);
  background: #F5F5F7;
  box-shadow: 0px 0px 0px 4px #f5f5f7 !important;
  //display: flex;
  //justify-content: center;
  //align-items: center;

}

.nftMedia {
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  //object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 16px;
  grid-template-areas:
    ". ."
    ". .";
  margin-bottom: 16px;
  border-radius: 16px;
  width: 100%;

}

.container div, .container img {
  border-radius: 16px;
  width: 100%;
  //min-height: 100px;
  //border: 1px solid black;
}

.artist {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
}

.card {
  background-color: #fff;
}

.artistImage {
  //max-width: 50px;
  width: 124px;
  height: 124px;
  border-radius: 50%;
}


</style>