<template>
  <div>
    <h1 class="title">Ether JS Demo</h1>
    <div class="content">
      <h2>Block explorer</h2>
      <button @click="deployContract">1) Deploy contract storage  (8.65$)</button>
      <br>
      <br>
      <input type="text" placeholder="0x1234 Contract adress to load" v-model="contracAdressToFetch">
      <button @click="getContract">2) get contract</button>
      <br>
      <br>
      <input type="number" v-model="numberToStore">
      <button @click="storeNumber(numberToStore)">3) storeNumber (13.90$)</button>
      <br>
      <br>
      <button @click="retrieveNumber">3) retrieveNumber</button>
      <br>
      <br>

      <input type="number" v-model="selectedBlockNumber">
      <button @click="getBlock(selectedBlockNumber)">Get Block</button>
      <div class="card" v-if="selectedBlock">
        <p>Block number: {{ selectedBlock.number }}</p>
        <p>Block Time stamp: {{ moment(selectedBlock.timestamp) }}</p>
        <p>Block Gas Limit: {{ selectedBlock.gasLimit }}</p>
        <p>Block Gas Used: {{ selectedBlock.gasUsed }}</p>
        <p>Block hash: {{ selectedBlock.hash }}</p>
        <p>Block parentHash: {{ selectedBlock.parentHash }}</p>
        <div>block transactions:
          <div v-for="transaction in selectedBlock.transactions" :key="transaction.hash">

            <div class="card" v-if="transaction">
              <h2>Transaction Data:</h2>
              <button @click="getTransaction(transaction)">Transaction</button>
              <button @click="getTransactionReceipt(transaction)">Transaction Receipt</button>
              <pre>{{ transaction }}</pre>
            </div>

          </div>
        </div>
        <!--    {{ selectedBlock }}-->

        <div class="card" v-if="selectedTransaction">
          <h2>Selected Transaction:</h2>
          <button @click="selectedTransaction = null">close</button>
          <pre>{{ selectedTransaction }}</pre>
        </div>
        <div class="card" v-if="selectedTransactionReceipt">
          <h2>SelectedTransaction Receipt:</h2>
          <button @click="selectedTransactionReceipt = null">close</button>
          <pre>{{ selectedTransactionReceipt }}</pre>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// Wichtige Dokumentation
// https://docs.ethers.io/v5/migration/web3/
// https://docs.ethers.io/v5/api/
import moment from 'moment'
import {ethers} from "ethers";


export default {
  name: "etherJsDemo",
  data() {
    return {
      moment: moment,
      provider: null,
      signer: null,
      selectedBlockNumber: 1,
      selectedBlock: null,
      selectedTransaction: null,
      selectedTransactionReceipt: null,
      factory: null,
      contract: null,
      numberToStore: 0,
      contracAdressToFetch: '',

      // Contract deployment and Usage relevant
      abi: [
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "num",
              "type": "uint256"
            }
          ],
          "name": "store",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "retrieve",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        }
      ],
      bytecode: {
        "functionDebugData": {},
        "generatedSources": [],
        "linkReferences": {},
        "object": "608060405234801561001057600080fd5b50610150806100206000396000f3fe608060405234801561001057600080fd5b50600436106100365760003560e01c80632e64cec11461003b5780636057361d14610059575b600080fd5b610043610075565b60405161005091906100d9565b60405180910390f35b610073600480360381019061006e919061009d565b61007e565b005b60008054905090565b8060008190555050565b60008135905061009781610103565b92915050565b6000602082840312156100b3576100b26100fe565b5b60006100c184828501610088565b91505092915050565b6100d3816100f4565b82525050565b60006020820190506100ee60008301846100ca565b92915050565b6000819050919050565b600080fd5b61010c816100f4565b811461011757600080fd5b5056fea2646970667358221220404e37f487a89a932dca5e77faaf6ca2de3b991f93d230604b1b8daaef64766264736f6c63430008070033",
        "opcodes": "PUSH1 0x80 PUSH1 0x40 MSTORE CALLVALUE DUP1 ISZERO PUSH2 0x10 JUMPI PUSH1 0x0 DUP1 REVERT JUMPDEST POP PUSH2 0x150 DUP1 PUSH2 0x20 PUSH1 0x0 CODECOPY PUSH1 0x0 RETURN INVALID PUSH1 0x80 PUSH1 0x40 MSTORE CALLVALUE DUP1 ISZERO PUSH2 0x10 JUMPI PUSH1 0x0 DUP1 REVERT JUMPDEST POP PUSH1 0x4 CALLDATASIZE LT PUSH2 0x36 JUMPI PUSH1 0x0 CALLDATALOAD PUSH1 0xE0 SHR DUP1 PUSH4 0x2E64CEC1 EQ PUSH2 0x3B JUMPI DUP1 PUSH4 0x6057361D EQ PUSH2 0x59 JUMPI JUMPDEST PUSH1 0x0 DUP1 REVERT JUMPDEST PUSH2 0x43 PUSH2 0x75 JUMP JUMPDEST PUSH1 0x40 MLOAD PUSH2 0x50 SWAP2 SWAP1 PUSH2 0xD9 JUMP JUMPDEST PUSH1 0x40 MLOAD DUP1 SWAP2 SUB SWAP1 RETURN JUMPDEST PUSH2 0x73 PUSH1 0x4 DUP1 CALLDATASIZE SUB DUP2 ADD SWAP1 PUSH2 0x6E SWAP2 SWAP1 PUSH2 0x9D JUMP JUMPDEST PUSH2 0x7E JUMP JUMPDEST STOP JUMPDEST PUSH1 0x0 DUP1 SLOAD SWAP1 POP SWAP1 JUMP JUMPDEST DUP1 PUSH1 0x0 DUP2 SWAP1 SSTORE POP POP JUMP JUMPDEST PUSH1 0x0 DUP2 CALLDATALOAD SWAP1 POP PUSH2 0x97 DUP2 PUSH2 0x103 JUMP JUMPDEST SWAP3 SWAP2 POP POP JUMP JUMPDEST PUSH1 0x0 PUSH1 0x20 DUP3 DUP5 SUB SLT ISZERO PUSH2 0xB3 JUMPI PUSH2 0xB2 PUSH2 0xFE JUMP JUMPDEST JUMPDEST PUSH1 0x0 PUSH2 0xC1 DUP5 DUP3 DUP6 ADD PUSH2 0x88 JUMP JUMPDEST SWAP2 POP POP SWAP3 SWAP2 POP POP JUMP JUMPDEST PUSH2 0xD3 DUP2 PUSH2 0xF4 JUMP JUMPDEST DUP3 MSTORE POP POP JUMP JUMPDEST PUSH1 0x0 PUSH1 0x20 DUP3 ADD SWAP1 POP PUSH2 0xEE PUSH1 0x0 DUP4 ADD DUP5 PUSH2 0xCA JUMP JUMPDEST SWAP3 SWAP2 POP POP JUMP JUMPDEST PUSH1 0x0 DUP2 SWAP1 POP SWAP2 SWAP1 POP JUMP JUMPDEST PUSH1 0x0 DUP1 REVERT JUMPDEST PUSH2 0x10C DUP2 PUSH2 0xF4 JUMP JUMPDEST DUP2 EQ PUSH2 0x117 JUMPI PUSH1 0x0 DUP1 REVERT JUMPDEST POP JUMP INVALID LOG2 PUSH5 0x6970667358 0x22 SLT KECCAK256 BLOCKHASH 0x4E CALLDATACOPY DELEGATECALL DUP8 0xA8 SWAP11 SWAP4 0x2D 0xCA 0x5E PUSH24 0xFAAF6CA2DE3B991F93D230604B1B8DAAEF64766264736F6C PUSH4 0x43000807 STOP CALLER ",
        "sourceMap": "141:356:0:-:0;;;;;;;;;;;;;;;;;;;"
      }
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      await this.connectMetaMask()
    },
    async connectMetaMask() {
      // Alternativen Provider verbinden (Ohne metamask, z.B nur privat key uns Publikc key)
      // const url = "HTTP://127.0.0.1:7545";
      // this.provider = new ethers.providers.JsonRpcProvider(url);
      // console.log(this.provider);

      this.provider = new ethers.providers.Web3Provider(window.ethereum)
      console.log(this.provider);

      await this.createSigner()
    },
    async createSigner() {
      // ethers (create random new account (Neues Wallet erstellen)
      // this.signer = ethers.Wallet.createRandom();

      // ethers (connect to JSON-RPC accounts) (Meta mask account Einloggen)
      await this.provider.send("eth_requestAccounts", []);
      this.signer = this.provider.getSigner();
      console.log("Account:", await this.signer.getAddress());
      await this.getBalance()
    },
    async getBalance() {
      let resp = await this.signer.getBalance()
      let BalanceInEther = ethers.utils.formatEther(resp)
      console.log(BalanceInEther)
    },
    async getBlock(blockHashOrBlockNumber) {
      // Block holen mit Transactions Refferenzen
      // this.selectedBlock = await this.provider.getBlock(blockHashOrBlockNumber)

      // Block Holen mit Transaktionen eingebettet (Erspart mehrere calls)
      this.selectedBlock = await this.provider.getBlockWithTransactions(blockHashOrBlockNumber)
      console.log(this.selectedBlock)
    },
    async getTransaction(transaction) {
      this.selectedTransaction = await this.provider.getTransaction(transaction.hash)
      console.log(this.selectedTransaction)
    },
    async getTransactionReceipt(transaction) {
      this.selectedTransactionReceipt = await this.provider.getTransactionReceipt(transaction.hash)
      console.log(this.selectedTransactionReceipt)
    },

    async deployContract() {
      // ethers
      this.factory = new ethers.ContractFactory(this.abi, this.bytecode, this.signer);
      this.contract = await this.factory.deploy();
      console.log('contract address', this.contract.address);
      this.contracAdressToFetch = this.contract.address;
      // wait for contract creation transaction to be mined
      await this.contract.deployTransaction.wait();
    },

    async getContract() {
      // (wird nur gebraucht wenn cortact bereits Deployed wurde, un nicht mehr im VueJs Stat ist)

      // Funktionen die was speichern oder machen brauchen einen Signer
      this.contract = await new ethers.Contract(this.contracAdressToFetch, this.abi, this.signer); // this.provider for Readonly acsess
      console.log(this.contract)
    },

    async storeNumber(numberToStore) {
      const tx = await this.contract.store(numberToStore);
      // wait for the transaction to be mined
      const receipt = await tx.wait();
      console.log(receipt)
    },

    async retrieveNumber() {
      const resp = await this.contract.retrieve();
      const HumanReadableNumber = resp.toNumber()
      // wait for the transaction to be mined
      console.log(HumanReadableNumber)
    }

  },
}
</script>

 <style scoped lang="scss">
@import 'src/styles/style.scss';


</style>