import Api from "./Api";
import axios from "axios"

class BackendApi {
    async createNft(collectionId, nftTokenId, nftURI, nftTx, user_id, creator_id, website, chainId) {
        const resp = await axios.post(Api.baseUrl + '/nfts', {
            user_id: user_id,
            creator_id: creator_id,
            collection_id: collectionId,
            nftTokenId: nftTokenId,
            nftURI: nftURI,
            nftTx: nftTx,
            website: website,
            chainId: chainId,
        })

        console.log(resp.data);
        return resp.data[0]
    }

    async createCollection(user_id,
                           collectionAddress,
                           collectionName,
                           collectionSymbol,
                           collectionDescription,
                           collectionWebsite,
                           isLimited,
                           mintingLimit,
                           isGenerative,
                           baseURI, isSelfMinting,
                           priceSelfMint,
                           isRevSharing,
                           RevSharingPercent,
                           RevSharingAddress,
                           chainId
    ) {
        const response = await axios.post(Api.baseUrl + '/collections', {
            user_id: user_id,
            collectionAddress: collectionAddress,
            collectionName: collectionName,
            collectionSymbol: collectionSymbol,
            collectionDescription: collectionDescription,
            website: collectionWebsite,
            isLimited:isLimited,
            mintingLimit:mintingLimit,
            isGenerative:isGenerative,
            baseURI:baseURI,
            isSelfMinting:isSelfMinting,
            priceSelfMint:priceSelfMint,
            isRevSharing:isRevSharing,
            RevSharingPercent:RevSharingPercent,
            RevSharingAddress:RevSharingAddress,
            chainId: chainId,
        })

        console.log(response.data);
        return response.data
    }
}

export default new BackendApi()