<template>
  <div class="home">

    <h1 class="title">Login or Register</h1>
    <div class="content">
      <h2>Please Login with register with MetaMask</h2><br>
      <div class="flexCenterCenter">
        <img class="illustration" src="@/assets/login.svg" alt="">
        <h2>Click on the Button in the top right corner</h2>
      </div>
    </div>


  </div>
</template>

<script>
// My own Ethers API Wrapper Service named ChainAPI
import ChainApi from "../services/ChainApi";
// import AuthMetamaskBtn from "../components/auth/authMetamaskBtn";


export default {
  name: 'LoginRegister',
  components: {
    // AuthMetamaskBtn
    // NftOverview
    // HelloWorld
  },
  data() {
    return {
      ChainApi: ChainApi,

      // Ethers Enteties
      provider: null,
      signer: null,

      toggle: {
        newContract: false,
        importContract: false,
        editReceiverAddress: false,
      },
      // Collection Deployment

      collection: {
        name: '',
        symbol: '',
        baseUrl: '',
        contract: null,
        address: '',
      },

      //Minting Nft Information
      NftReceiverAddress: '',

      NFT: {
        name: '',
        description: '',
        meta: {}
      },
      URI: '',
      URIMetaList: [],

      nftInContractCount: 0,

    }
  },
  mounted() {
    this.init()
  },
  activated() {
    this.init()
  },
  methods: {
    async init() {

    },

    async getPermissions() {
      this.provider = await this.ChainApi.connectMetaMask()
      this.signer = await this.ChainApi.createSigner(this.provider)
      this.NftReceiverAddress = await this.ChainApi.getPublicAddress(this.signer)
    },

    async transferNFTOwnership() {
      // Call ERC 20 Transfer Method
    },


    async getTokenURI(tokenId) {
      const nftURI = await this.collection.contract.tokenURI(tokenId)
      console.log({
        title: 'NFT URI',
        data: nftURI
      })
      this.URIMetaList.push(nftURI)
    },


    async CollectionContractToDB(contractAddress, collectionName, collectionSymbol
    ) {
      // Save nft not a Listing!
      // here are no Prices involved
      console.log('...Saving Collection to users DB')
      console.log({
        contractAddress: contractAddress,
        collectionName: collectionName,
        collectionSymbol: collectionSymbol,
      })
    },

    async NftToDB(tokenId, uri) {
      // Save nft not a Listing!
      // here are no Prices involved
      console.log('...Saving NFT to users DB')
      console.log({
        tokenId: tokenId,
        uri: uri,
      })
    },

  },
}
</script>
 <style scoped lang="scss">
@import 'src/styles/style.scss';

img {
  max-width: 100%;
  border-radius: 12px;
  margin-bottom: 16px;
}

.nftFileUpload {
  display: none;
}

.nftFileUploadBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.receiverAddress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}


.content {
  margin-bottom: 100px;
}


.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 18px 18px;
  grid-auto-flow: row;

}



.nft_listing {
  //max-width: 100%;

  img {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 16px;
  }
}

.nft_upper_meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
